.fluent-form {
  gap: 40px;
  display: grid;
  width: 520px;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    width: calc(100vw - 20px);
  }
  .form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    h2 {
      margin: 0px;
      text-align: center;
    }
    h6 {
      margin: 0px;
      color: #8692a6;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #729595;

    padding: 40px;
    border-radius: 4px;

    @media only screen and (max-width: 600px) {
      padding: 0px 0px;
      border: none;
    }
  }
}
