.header {
  height: 60px;
  padding: 60px 0px 00px;
  display: flex;
  justify-content: center;
  img {
    height: 60px;
    object-fit: cover;
    width: 142px;
  }
}
